.App {
  /*display: flex;*/
  /*height: 100vh;*/
  /*justify-content: center;*/
  /*font-weight: 500;*/
}

.ant-table-sticky-holder {
  background: transparent;
}

.ant-card-bordered {
  border-color: #343a4014;
}

.ant-result-title {
  color: #1A1A22;
}

.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link {
  color: #9393ab91;
  border-color: #9393ab91;
  cursor: not-allowed;
}

.ant-table-filter-trigger,
.ant-table-column-sorter {
  color: #FFFFFF;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  color: #1A1A22;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("assets/images/login_bg.svg");
}

.login-font {
  color: rgba(255, 255, 255, 0.85) !important;
}

.ant-notification-notice-close:hover {
  color: #1a1a22b8;
}

.radio-large .ant-radio .ant-radio-inner {
  width: 1.5em;
  height: 1.5em;
}

.test-form-layout {
  width: 100%;
  padding: 3% 5%;
}

.test-question-title-font {
  font-size: 2em;
  font-weight: 500;
}

.test-question-answer-font {
  font-size: 1.5em;
  font-weight: 500;
  color: #1a1a2299;
}

.header_logo {
  display: flex;
  width: 165px;
  margin-left: -35px;
}

.ant-menu {
  width: 100%;
  background: transparent !important;
}

.ant-card {
  border-radius: 6px;
}

.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  min-height: 36px;
  min-width: 37px;
  height: unset;
  -webkit-box-shadow:  0 0 18px 0 rgb(34 60 80 / 10%);
  -moz-box-shadow:  0 0 18px 0 rgb(34 60 80 / 10%);
  box-shadow: 0 0 18px 0 rgb(34 60 80 / 10%);
}

.ant-input:placeholder-shown {
  text-shadow: 0 0 #9393AB;
}

.ant-btn[disabled], .ant-dropdown-menu-title-content .ant-btn{
  box-shadow: none
}

.ant-input-search-button {
  min-height: 35px;
}

.ant-modal-confirm .ant-modal-body {
  padding: 32px 30px 24px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 13px 13px;
}

.ant-btn-default:hover,
.ant-btn-dashed:hover {
 color: #438A84;
 border-color: #438A84;
}

.ant-btn-primary:disabled,
.ant-btn-default:disabled,
.ant-btn-dashed:disabled {
  border-color: #9393AB;
}

.ant-divider-vertical {
  height: inherit;
}

.text-secondary {
  color: #1a1a2296 !important;
}

.ant-btn-dangerous {
  color: #FF4954;
  border-color: #FF4954 !important;
  background: transparent;
}

.ant-btn-dangerous[disabled] {
  opacity: 0.8;
  color: #FF4954 !important;
  border-color: #FF4954 !important;
}

.ant-btn-dangerous:hover {
  color: #ff000f;
  border-color: #ff000f !important;
  background: transparent;
}

.ant-btn.ant-btn-background-ghost[disabled] {
  border-color: transparent;
}

.ant-table-filter-trigger {
  color: #FFFFFF;
  background: transparent;
  padding: 10px;
}

.ant-table-filter-trigger:hover,
.ant-table-filter-trigger:focus,
.ant-table-filter-trigger.active {
  color: #FFFFFF;
  background: transparent;
  padding: 10px;
  -webkit-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2) inset;
  -moz-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2) inset;
  box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2) inset;
}

.ant-menu-item-selected:hover {
  -webkit-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2);
  box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2);
}

.ant-menu-item-selected {
  -webkit-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2) inset;
  -moz-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2) inset;
  box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2) inset;
}

.ant-table-tbody > tr > td {
  border: unset;
}

.ant-menu-item {
  border-radius: 4px;
  line-height: 40px;
  margin-right: 6px !important;
}

.ant-btn-link {
  display: flex;
  align-items: center;
  box-shadow: none !important;
  padding: unset;
}

.ant-btn-link:hover, .ant-btn-link.active {
  color: #4B706C !important;
}

.pointer {
  cursor: pointer;
  -webkit-box-shadow:  0 0 18px 0 rgb(34 60 80 / 60%);
  -moz-box-shadow:  0 0 18px 0 rgb(34 60 80 / 60%);
  box-shadow: 0 0 18px 0 rgb(34 60 80 / 60%);
}

.pointer:hover {
  cursor: pointer;
  -webkit-box-shadow:  0 0 18px 0 rgb(34 60 80 / 60%) inset;
  -moz-box-shadow:  0 0 18px 0 rgb(34 60 80 / 60%) inset;
  box-shadow: 0 0 18px 0 rgb(34 60 80 / 60%) inset;
}

.header {
  display: flex !important;
  align-items: center !important;
  position: fixed;
  z-index: 1000;
  width: 100%;
}

.ant-modal-header {
  border-bottom: 1px solid #E8E9F2;
}

.ant-form-item-label > label {
   color: #1a1a22a8;
}

.ant-modal-close-x {
  height: 64px;
  font-size: 20px;
  line-height: 66px;
}

.ant-modal-close:focus, .ant-modal-close:hover {
  color: #4b706cd6;
}

.ant-layout-content {
  margin-top: 65px;
  overflow-y: scroll;
}

.ant-input {
  padding: 4px 11px;
  border: 1px solid #9393ab69;
}

.ant-input[disabled], .ant-input[disabled]:hover {
  border-color: #9393ab69;
  background-color: #b3b3b50d;
}

.ant-input-affix-wrapper {
  border: 1px solid #9393ab69;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #438a8482;
  padding: 10px 12px;
}

.ant-select-item {
  padding: 10px 12px;
}

.ant-input-prefix {
  color: #1a1a2285;
}

.ant-empty-image svg {
  opacity: 0.82;
}

.ant-layout-header {
  padding: 0 40px;
}

.ant-breadcrumb {
  margin-bottom: 15px;
}

.site-layout-background {
  background: #F8F9FA;
}

.ant-btn:disabled {
  opacity: 0.6;
}

.btn-warning {
  background: #ffcf5fd4;
  border-color: #ffcf5fd4;
  color: #F8F9FA;
}

.btn-warning:active,
.btn-warning:hover,
.btn-warning:focus {
  color: #ffcf5fd4;
  border-color: #ffcf5fd4;
}

.btn-red {
  background: #FF4954cc;
  border-color: #FF4954cc;
  color: #F8F9FA;
}

.btn-red:active,
.btn-red:hover,
.btn-red:focus {
  color: #FF4954cc;
  border-color: #FF4954cc;
}

.btn-green {
  background: #15CB84c7;
  border-color: #15CB84c7;
  color: #F8F9FA;
}

.btn-green:active,
.btn-green:hover,
.btn-green:focus {
  color: #15CB84c7;
  border-color: #15CB84c7;
}

.ant-card-head {
  color: unset;
  font-size: unset;
  border-bottom: 1px solid #343a4021;
}

.colored-card-head .ant-card-head {
  background: #91c7c342;
}

.test-question-text-font {
  font-size: 22px;
  font-weight: 500;
}

.ant-card-head-title {
  padding: 10px 0;
}

.ant-badge-dot {
  width: 9px;
  min-width: 9px;
  height: 9px;
  box-shadow: unset;
}

.ant-descriptions-item-container .ant-descriptions-item-label, .ant-descriptions-item-container .ant-descriptions-item-content {
  align-items: center;
}

.ant-descriptions-item-label {
  font-weight: 500;
}

.ant-card-head-title {
  font-size: 17px;
}

.ant-dropdown {
  background: white;
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 48%), 0 6px 16px 0 rgb(0 0 0 / 32%), 0 9px 28px 8px rgb(0 0 0 / 20%);
}

.ant-list-item-meta-description {
  line-height: 1.5;
}

.notification-dropdown {
  max-height: 490px;
  overflow-y: scroll;
}

.progress-grey .ant-progress-outer .ant-progress-inner .ant-progress-bg {
  background-color: #c8c8c852;
  border-radius: 6px;
}

.ant-badge-dot {
  top: 1px;
  right: 7px;
}

.ant-list-split .ant-list-item {
  border-bottom: 1px solid #343a4024;
}

.ant-list-item {
  display: inherit;
  padding: 10px 10px;
}

.kt-w-100 {
  width: 100%;
}

.kt-mr-5 {
  margin-right: 5px;
}

.kt-mr-15 {
  margin-right: 15px;
}

.kt-mb-5 {
  margin-bottom: 5px;
}

.kt-mb-15 {
  margin-bottom: 15px;
}

.ant-select-selection-placeholder {
  color: #9393ab !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #9393ab78;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow {
  color: #1a1a22b8;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  padding: 3px 11px;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
   background: #4b706cd6;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  border-color: #9393ab69 !important;
}

.checkbox-large .ant-checkbox .ant-checkbox-inner {
  width: 30px;
  height: 30px;
}

.checkbox-large  .ant-checkbox-inner::after {
  width: 12px;
  height: 16px;
}

.ant-divider-horizontal.ant-divider-with-text {
  color: #1a1a2291;
}

.flex {
  display: flex;
}

.countdown-time {
  font-size: 16px;
  font-weight: 500;
  color: rgba(26, 26, 34, 0.42);
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-row-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.widget-users-img {
  width: 14rem;
  margin-top: -105px;
}

.widget-time-img {
  width: 12rem;
  margin-top: -70px;
}

.lg-font {
  font-size: 17px;
}

@media only screen and (max-width: 820px) {
  .header_logo {
    width: 210px;
    margin-left: -20px;
  }

  .ant-layout-header {
    padding: 0 20px;
  }

  .ant-layout-content {
    padding: 10px 15px !important;
  }

  .ant-card-body {
    padding: 14px;
  }

  .ant-breadcrumb {
    margin-bottom: 10px;
  }

  .ant-checkbox .ant-checkbox-inner{
    width: 26px;
    height: 26px;
  }

  .ant-checkbox-inner::after {
    width: 10px;
    height: 14px;
  }

  .lg-font {
    font-size: 15px;
  }

  .test-form-layout {
    padding: unset;
  }

  .radio-large .ant-radio .ant-radio-inner {
    width: 1em;
    height: 1em;
  }

  .test-question-title-font {
    font-size: 1.5em;
    font-weight: 500;
  }

  .test-question-answer-font {
    font-size: 1em;
    font-weight: 500;
  }

  .ant-card-head {
    padding: 0 12px;
  }

  .widget-users-img {
    width: 13rem;
    margin-top: -80px;
  }

  .widget-time-img {
    width: 12rem;
    margin-top: -70px;
  }

}
